import styled from '@emotion/styled'

import { toRem, toRems } from '@hmn/rtl-zena-ui/helpers/theme'

const GRID_COLUMNS = 24 // site value

const GRID_COLUMNS_BASE = 12 // cms value
const GRID_SCALE = GRID_COLUMNS / GRID_COLUMNS_BASE // multiplier

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({})

export const Grid = styled.div(({ theme }) => ({
    '& .widgetWrapper': {
        padding: toRems([0, 25])
    },
    [theme.breakpoints.up('md')]: {
        display: 'grid',
        // padding: '0 !important',
        gridTemplateColumns: `repeat(${GRID_COLUMNS}, 1fr)`,
        alignItems: 'center'
    }
}))

export const Cell = styled.div(({ theme, itemCount, position }) => {
    const gridProps = {}
    if (position) {
        const { x, y, w, h } = position

        gridProps.gridColumnStart = GRID_SCALE * x + 1
        gridProps.gridColumnEnd = GRID_SCALE * (x + w) + 1
        gridProps.gridRowStart = y + 1
        gridProps.gridRowEnd = y + h + 1
        if (itemCount) {
            const columnWidth = w / itemCount < 6 ? 4 : 6
            const offsetX = (columnWidth - (x % columnWidth)) % columnWidth
            const cellCount = GRID_COLUMNS_BASE / columnWidth
            gridProps.display = 'grid'
            // gridProps.gridGap = toRem(40)
            // gridProps.paddingLeft = `calc(100% * ${offsetX} / ${w})`
            if (offsetX !== 0) {
                gridProps.gridTemplateColumns = '1fr'
            } else {
                gridProps.gridTemplateColumns =
                    Number.isInteger(cellCount) || itemCount > 1
                        ? `repeat(auto-fill, calc((100% * ${GRID_COLUMNS_BASE} / ${w - offsetX}) / ${cellCount}))`
                        : '1fr'
            }
        }
    }
    return {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            '& > div': {
                padding: toRem(25)
            },
            ...gridProps
        },
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                marginBottom: toRem(35)
            }
        }
    }
})

export default style
