import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Graphic, graphicFillVariants, graphicVariants } from '../Decoration/components'
import styles from './Divider.style'

const DividerStyled = styled.div(props => ({ ...styles(props) }))

function Divider({ isEmpty, className, ...rest }) {
    return (
        <DividerStyled isEmpty className={className} {...rest}>
            {!isEmpty && (
                <Graphic
                    className="divider_graphic"
                    variant={graphicVariants.ETA}
                    fillVariant={graphicFillVariants.ALTERNATIVE}
                />
            )}
        </DividerStyled>
    )
}

Divider.propTypes = {
    className: PropTypes.string,
    isEmpty: PropTypes.bool
}

Divider.defaultProps = {
    className: undefined,
    isEmpty: undefined
}

export default Divider
