/* eslint-disable @nx/enforce-module-boundaries */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { Luna } from '@hmn/rtl-web-core/controllers'
import { getWidgetZoneName } from '@hmn/rtl-web-core/helpers/ads'
import { getOneElastic } from '@hmn/rtl-web-core/hooks'
import { handleError, returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import AdSlot from '@hmn/rtl-zena-ui/components/Ad/ZenaSlot.component'
import { Campaign } from '@hmn/rtl-zena-ui/components/Campaign'
import { CardsBlock, cardsBlockVariants } from '@hmn/rtl-zena-ui/components/CardsBlock'
import { Divider } from '@hmn/rtl-zena-ui/components/Divider'
import { EntityCard } from '@hmn/rtl-zena-ui/components/EntityCard'
import { HtmlContent } from '@hmn/rtl-zena-ui/components/HtmlContent'
import { imageRatioVariants } from '@hmn/rtl-zena-ui/components/Image'
import { DynamicListalicaProizvoda } from '@hmn/rtl-zena-ui/components/ListalicaProizvoda'
import { Menu, menuVariants } from '@hmn/rtl-zena-ui/components/Navigation/components/Menu'
import { Newsletter, newsletterVariants } from '@hmn/rtl-zena-ui/components/Newsletter'
import { SliderWrapper } from '@hmn/rtl-zena-ui/components/SliderWrapper'
import { Special } from '@hmn/rtl-zena-ui/components/Special'
import { Title, titleDecorations, titleSizes } from '@hmn/rtl-zena-ui/components/Title'
import { toRems } from '@hmn/rtl-zena-ui/helpers'

import styles, { Cell, Grid } from './LayoutBuilder.style'

const ArticlePoll = dynamic(() => import('./ArticlePoll.local.component').then(m => m.ArticlePoll), { ssr: false })

const LayoutBuilderStyled = styled.div(props => ({ ...styles(props) }))

export const ComponentType = {
    CONTENT_HANDPICKED: 'CONTENT_HANDPICKED',
    CONTENT_AUTOMATIC: 'CONTENT_AUTOMATIC',
    HEADING: 'HEADING',
    DIVIDER: 'DIVIDER',
    NAVIGATION: 'NAVIGATION',
    RICHTEXT: 'RICHTEXT',
    SPECIAL: 'SPECIAL',
    BORDER_BOX: 'BORDER_BOX',
    BANNER: 'BANNER',
    POLL: 'Poll',
    IFRAME: 'IFRAME',
    LISTALICA: 'PRODUCTGALLERIES',
    NEWSLETTER: 'NEWSLETTER'
}

const ImageVariation = {
    '3:2': imageRatioVariants.CUSTOM_LANDSCAPE,
    '4:5': imageRatioVariants.CUSTOM_4x5,
    '2:3': imageRatioVariants.CUSTOM_PORTRAIT,
    '1:1': imageRatioVariants.CUSTOM_SQUARE,
    square: imageRatioVariants.CUSTOM_SQUARE
}

function WidgetContent({ items, position }) {
    const itemCount = items?.length || 1

    return (
        <Cell itemCount={itemCount} position={position}>
            {items?.map(({ key, item, variant }) =>
                item ? (
                    <EntityCard
                        key={key}
                        item={item}
                        imageVariation={variant?.aspectRatio ? ImageVariation[variant.aspectRatio] : undefined}
                        imageLazyLoad={position.y > 5}
                        imageWidth={1000}
                        imageHeight={1500}
                        cardVariant={variant?.type}
                        cardDecorationVariant={variant?.decoration}
                    />
                ) : (
                    <div key={key} />
                )
            )}
        </Cell>
    )
}
WidgetContent.propTypes = {
    position: PropTypes.shape({
        y: PropTypes.number,
        w: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetContent.defaultProps = {
    position: undefined,
    items: []
}

function WidgetHeading({ url, title, decorations, position, size }) {
    return (
        <Cell position={position}>
            <Title
                titleHref={url}
                title={title}
                titleTagComponent="h2"
                size={titleSizes[size] || 'normal'}
                decoration={titleDecorations[decorations]}
                isItalic
                isNav={!!url}
            />
        </Cell>
    )
}
WidgetHeading.propTypes = {
    position: PropTypes.shape({}),
    title: PropTypes.string,
    size: PropTypes.string,
    decorations: PropTypes.string,
    url: PropTypes.string
}
WidgetHeading.defaultProps = {
    position: {},
    title: '',
    size: undefined,
    decorations: undefined,
    url: undefined
}

function WidgetDivider({ style, position }) {
    return <Cell position={position}>{style === '3' ? <Campaign /> : <Divider isEmpty={style === '1'} />}</Cell>
}
WidgetDivider.propTypes = {
    position: PropTypes.shape({}),
    style: PropTypes.string
}
WidgetDivider.defaultProps = {
    position: {},
    style: ''
}

function WidgetNavigation({ position, items }) {
    if (!items) {
        return null
    }

    return (
        <Cell position={position}>
            <SliderWrapper>
                <Menu links={items} variant={menuVariants.EPSILON} />
            </SliderWrapper>
        </Cell>
    )
}

export async function getWidgetNavigationData({ navigation }) {
    const { data } = getOneElastic(`navigation/${navigation}`).catch(handleError(returnEmptyOn4xx))

    return {
        items: data
    }
}

WidgetNavigation.propTypes = {
    position: PropTypes.shape({}),
    items: PropTypes.shape({})
}

WidgetNavigation.defaultProps = {
    position: {},
    items: null
}

function WidgetRichtext({ richtext, position }) {
    return (
        <Cell position={position}>
            <HtmlContent data={richtext} />
        </Cell>
    )
}
WidgetRichtext.propTypes = {
    position: PropTypes.shape({}),
    richtext: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetRichtext.defaultProps = {
    position: {},
    richtext: []
}

function WidgetPoll({ position, id }) {
    if (!id) {
        return null
    }

    return (
        <Cell position={position}>
            <ArticlePoll id={id} isLessThanHalf={position?.w <= 6} />
        </Cell>
    )
}
WidgetPoll.propTypes = {
    position: PropTypes.shape({
        w: PropTypes.number
    }),
    id: PropTypes.string
}
WidgetPoll.defaultProps = {
    position: {},
    id: ''
}

function WidgetIFrame({ position, title, source, height }) {
    return (
        <Cell position={position}>
            <iframe title={title} src={source} height={height} width="100%" />
        </Cell>
    )
}
WidgetIFrame.propTypes = {
    position: PropTypes.shape({}),
    title: PropTypes.string,
    source: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
WidgetIFrame.defaultProps = {
    position: {},
    title: undefined,
    source: undefined,
    height: undefined
}

function WidgetSpecial({ items, title, subtitle, lead, imageId, position }) {
    return (
        <Cell position={position}>
            <Luna theme="zenaDark">
                <Special
                    label={subtitle}
                    title={title}
                    // titleTag=""
                    lead={lead}
                    image={imageId}>
                    {items?.map(({ key, item, variant }) => (
                        <EntityCard
                            key={key}
                            item={item}
                            cardVariant={variant?.type}
                            imageVariation={variant?.aspectRatio ? ImageVariation[variant.aspectRatio] : undefined}
                            imageLazyLoad={position.y > 5}
                            cardDecorationVariant={variant?.decoration}
                        />
                    ))}
                </Special>
            </Luna>
        </Cell>
    )
}
WidgetSpecial.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    imageId: PropTypes.string,
    position: PropTypes.shape({
        y: PropTypes.number
    }),
    items: PropTypes.arrayOf(PropTypes.shape({}))
}
WidgetSpecial.defaultProps = {
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    imageId: undefined,
    position: undefined,
    items: []
}

function WidgetBorderBox({ url, items, title, position }) {
    const gtmDataArticles = {
        eventCategory: 'Related Content Card',
        eventAction: 'Najčitaniji članci',
        eventLabel: 'Below content',
        eventValue: 'Card content'
    }
    return (
        <Cell position={position}>
            <CardsBlock variant={cardsBlockVariants.BETA} title={title} url={url}>
                {items?.map(({ key, item, variant }) => (
                    <EntityCard
                        key={key}
                        item={item}
                        cardVariant={variant?.type}
                        imageLazyLoad={position.y > 5}
                        // imageVariation={variant?.aspectRatio ? ImageVariation[variant.aspectRatio] : undefined}
                        cardDecorationVariant={variant?.decoration}
                        gtmData={gtmDataArticles}
                    />
                ))}
            </CardsBlock>
        </Cell>
    )
}
WidgetBorderBox.propTypes = {
    position: PropTypes.shape({
        y: PropTypes.number
    }),
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string
}
WidgetBorderBox.defaultProps = {
    position: undefined,
    title: undefined,
    items: [],
    url: undefined
}

function WidgetBanner({ position, adPosition, deviceProp }) {
    // console.log({ position, adPosition })
    const zoneName = useMemo(
        () =>
            deviceProp
                ? { [deviceProp]: getWidgetZoneName(adPosition?.[deviceProp]) }
                : { desktop: getWidgetZoneName(adPosition?.desktop), mobile: getWidgetZoneName(adPosition?.mobile) },
        [adPosition, deviceProp]
    )
    return (
        <Cell
            position={position}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'stretch',
                padding: position?.w === 12 ? 0 : toRems([5, 0, 10, 15]),
                margin: toRems(position?.w === 12 ? [0, -15] : [0, 0, 15])
            }}>
            <div
                style={{
                    padding: 0,
                    margin: 0,
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>
                <AdSlot isWidgetBanner position={zoneName} />
            </div>
        </Cell>
    )
}
WidgetBanner.propTypes = {
    position: PropTypes.shape({ w: PropTypes.number, h: PropTypes.number }),
    adPosition: PropTypes.shape({
        desktop: PropTypes.shape({}),
        mobile: PropTypes.shape({})
    }),
    deviceProp: PropTypes.string
}
WidgetBanner.defaultProps = {
    position: {},
    adPosition: undefined,
    deviceProp: undefined
}

function WidgetListalica({ position, listalica, logoImage }) {
    if (!listalica) {
        return null
    }

    return (
        <Cell position={position}>
            <DynamicListalicaProizvoda
                title={listalica?.title}
                items={listalica?.extended_attributes?.product_galleries_products}
                showLogo={!listalica?.extended_attributes.product_galleries_show_title}
                logoImage={logoImage}
                titleUrl={listalica?.extended_attributes?.product_galleries_url}
                disclaimer={listalica?.extended_attributes?.product_galleries_disclaimer}
                isWidget
            />
        </Cell>
    )
}

WidgetListalica.propTypes = {
    position: PropTypes.shape({}),
    listalica: PropTypes.shape({
        title: PropTypes.string,
        extended_attributes: PropTypes.shape({
            product_galleries_products: PropTypes.arrayOf(PropTypes.shape({})),
            product_galleries_show_title: PropTypes.bool,
            product_galleries_url: PropTypes.string,
            product_galleries_disclaimer: PropTypes.string
        }),
        image: PropTypes.shape({})
    }).isRequired,
    logoImage: PropTypes.shape({})
}

WidgetListalica.defaultProps = {
    position: {},
    logoImage: undefined
}

async function getWidgetListalicaData({ id }) {
    const { data: listalica } = await getOneElastic(`entity/${id}`, {
        preview: true
    }).catch(handleError(returnEmptyOn4xx))

    const logoImage = await (async () => {
        if (listalica?.image?.id) {
            const imageResponse = await getOneElastic(`image/${listalica.image.id}`).catch(
                handleError(returnEmptyOn4xx)
            )

            return imageResponse.data
        }

        return undefined
    })()

    return {
        listalica,
        logoImage
    }
}

function WidgetNewsletter({ position }) {
    return (
        <Cell position={position}>
            {/* eslint-disable-next-line react/prop-types */}
            <Newsletter variant={position?.w < 6 ? newsletterVariants.BETA : newsletterVariants.ALPHA} />
        </Cell>
    )
}
WidgetNewsletter.propTypes = {
    position: PropTypes.shape({})
}
WidgetNewsletter.defaultProps = {
    position: {}
}

export const WidgetComponents = {
    [ComponentType.CONTENT_HANDPICKED]: WidgetContent,
    [ComponentType.SPECIAL]: WidgetSpecial,
    [ComponentType.CONTENT_AUTOMATIC]: WidgetContent,
    [ComponentType.BORDER_BOX]: WidgetBorderBox,
    [ComponentType.HEADING]: WidgetHeading,
    [ComponentType.DIVIDER]: WidgetDivider,
    [ComponentType.NAVIGATION]: WidgetNavigation,
    [ComponentType.RICHTEXT]: WidgetRichtext,
    [ComponentType.BANNER]: WidgetBanner,
    [ComponentType.POLL]: WidgetPoll,
    [ComponentType.IFRAME]: WidgetIFrame,
    [ComponentType.LISTALICA]: WidgetListalica,
    [ComponentType.NEWSLETTER]: WidgetNewsletter
}

export const componentGetDataMap = {
    [ComponentType.LISTALICA]: getWidgetListalicaData,
    [ComponentType.NAVIGATION]: getWidgetNavigationData
}

function LayoutBuilder({ widgetListWithData, deviceProp }) {
    return (
        <LayoutBuilderStyled>
            <Grid>
                {(widgetListWithData || []).map(({ Component, props, key, position }) => {
                    if (!Component) {
                        return null
                    }
                    // eslint-disable-next-line react/prop-types
                    const needWrapping = deviceProp === 'mobile' && props?.richtext
                    if (needWrapping) {
                        return (
                            <div key={key} className="widgetWrapper">
                                <Component position={position} deviceProp={deviceProp} {...props} />
                            </div>
                        )
                    }
                    return <Component key={key} position={position} deviceProp={deviceProp} {...props} />
                })}
            </Grid>
        </LayoutBuilderStyled>
    )
}

LayoutBuilder.propTypes = {
    layout: PropTypes.arrayOf(PropTypes.shape({})),
    deviceProp: PropTypes.string,
    widgetListWithData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)])
}

LayoutBuilder.defaultProps = {
    layout: undefined,
    deviceProp: 'mobile',
    widgetListWithData: []
}

export default LayoutBuilder
