/* eslint-disable max-len */
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../../../Button'
import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { Graphic, graphicVariants } from '../../../Decoration/components'
import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Card.style'

const ListalicaCardStyled = styled.div(props => ({ ...styles(props) }))

function ListalicaCard({
    titleTagComponent: TitleTag,
    buttonLabel,
    buttonHref,
    price,
    oldPrice,
    priceEuro,
    priceOldEuro,
    description,
    title,
    imageId,
    imageLabel,
    onClick,
    imageView
}) {
    const titleHtmlProps = useInnerHtml(title)
    const imageLabelHtmlProps = useInnerHtml(imageLabel)

    if (!imageId || !buttonHref) {
        return null
    }

    const hasAnyPrice = price || priceEuro

    return (
        <ListalicaCardStyled onClick={onClick}>
            <Graphic className="listalicaCard_graphic" widthXs={30} widthMd={22} variant={graphicVariants.GAMMA} />
            <ConditionalWrapper
                condition={!!buttonHref}
                Wrapper={Link}
                href={buttonHref}
                as={buttonHref}
                isExternal
                className={imageView ? '' : 'fullHeight'}
                title={buttonHref}>
                {imageView ? (
                    <Image
                        classNameProgressive="listalicaCard_imageWrapper_image"
                        image={imageId}
                        ratio="0.6"
                        width="480"
                        height="800"
                        alt={title}
                        original
                        lazyLoad={false}
                    />
                ) : (
                    <>
                        <div className="listalicaCard_contentWrapper">
                            <div className="listalicaCard_imageWrapper">
                                {imageLabel && (
                                    <TitleTag className="listalicaCard_imageWrapper_label" {...imageLabelHtmlProps} />
                                )}
                                <Image
                                    classNameProgressive="listalicaCard_imageWrapper_image"
                                    variation={imageRatioVariants.CUSTOM_SQUARE}
                                    image={imageId}
                                    alt={title}
                                    lazyLoad={false}
                                />
                            </div>
                            <TitleTag className="listalicaCard_title" {...titleHtmlProps} />
                            {hasAnyPrice && (
                                <div className="listalicaCard_priceWrapper">
                                    {priceEuro && (
                                        <p>
                                            <span className="listalicaCard_priceWrapper_price">
                                                {priceEuro}
                                                <span className="listalicaCard_priceWrapper_price_currency">€</span>
                                            </span>
                                            {priceOldEuro && (
                                                <span className="listalicaCard_priceWrapper_oldPrice">
                                                    {priceOldEuro}€
                                                </span>
                                            )}
                                        </p>
                                    )}
                                    {price && (
                                        <p>
                                            <span className="listalicaCard_priceWrapper_price listalicaCard_priceWrapper_price_sm">
                                                {price}
                                                <span className="listalicaCard_priceWrapper_price_currency listalicaCard_priceWrapper_price_currency_sm">
                                                    kn
                                                </span>
                                            </span>
                                            {oldPrice && (
                                                <span className="listalicaCard_priceWrapper_oldPrice listalicaCard_priceWrapper_oldPrice_sm">
                                                    {oldPrice}kn
                                                </span>
                                            )}
                                        </p>
                                    )}
                                </div>
                            )}
                            {description && <div className="listalicaCard_description">{description}</div>}
                        </div>
                        <div className="listalicaCard_ctaWrapper">
                            <Button
                                title={buttonLabel || 'Pogledaj ponudu'}
                                className="listalicaCard_button"
                                width="55%"
                                widthMobile="48%"
                                variant={buttonVariants.BETA}>
                                {buttonLabel || 'Pogledaj ponudu'}
                            </Button>
                        </div>
                    </>
                )}
            </ConditionalWrapper>
        </ListalicaCardStyled>
    )
}

ListalicaCard.propTypes = {
    className: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    buttonLabel: PropTypes.string,
    buttonHref: PropTypes.string,
    price: PropTypes.string,
    oldPrice: PropTypes.string,
    priceEuro: PropTypes.string,
    priceOldEuro: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    imageId: PropTypes.string,
    imageLabel: PropTypes.string,
    onClick: PropTypes.func,
    imageView: PropTypes.bool
}

ListalicaCard.defaultProps = {
    className: undefined,
    titleTagComponent: 'h4',
    buttonLabel: undefined,
    buttonHref: undefined,
    price: undefined,
    oldPrice: undefined,
    priceEuro: undefined,
    priceOldEuro: undefined,
    description: undefined,
    title: undefined,
    imageId: undefined,
    imageLabel: undefined,
    onClick: undefined,
    imageView: false
}

export default ListalicaCard
