/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ isDesktop }) => ({
    width: 'auto',
    maxWidth: '100%',
    '.sliderWrapper': {
        position: 'relative',
        width: 'auto',
        maxWidth: '100%',
        '&_inner': {
            position: 'relative',
            width: 'fit-content',
            whiteSpace: isDesktop ? 'initial' : 'nowrap'
        }
    }
})

export default style
