import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Button, buttonVariants } from '../Button'
import { Graphic, graphicVariants } from '../Decoration/components'
import { Icon, NewsletterIcon, UnionIcon } from '../Icon'
import styles from './Newsletter.style'

const NewsletterStyled = styled.div(props => ({ ...styles(props) }))

function Newsletter({ className, titleTagComponent: TitleTag, variant, ...rest }) {
    const [message, setMessage] = useState('')
    const newsletterForm = useRef()
    const [isDesktop] = useBreakpoints('md')

    const onSubmit = (values, { setSubmitting, setFieldValue }) => {
        if (!window.exponea) {
            setMessage('Dogodila se greška! Pokušajte osvježiti stranicu.')
            setSubmitting(false)
            return
        }

        if (!values.email) {
            setMessage('Unesi svoju e-mail adresu')
            setSubmitting(false)
            return
        }

        const emailRegTest = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        if (!emailRegTest.test(values.email)) {
            setMessage('Unesi ispravnu e-mail adresu')
            setSubmitting(false)
            return
        }

        window.exponea.identify(
            { email_id: values.email },
            { email: values.email, zena_hr: true, marketing_consent: 'Yes' },
            () => {
                window.exponea.track('consent', {
                    action: 'accept',
                    category: 'zena.hr',
                    valid_until: 'unlimited',
                    email: values.email
                })

                setMessage('Uspješno si se pretplatila, hvala na pretplati!')
                setFieldValue('email', '')
                setSubmitting(false)
            },
            error => {
                setMessage(`Dogodila se greška! Pokušajte osvježiti stranicu. Greška: ${error.message}`)
                setSubmitting(false)
            },
            false
        )
    }

    return (
        <NewsletterStyled className={className} variant={variant} {...rest}>
            <Graphic className="newsletter_graphic_horizontal" variant={graphicVariants.KAPPA} />
            <Graphic className="newsletter_graphic_vertical" variant={graphicVariants.KAPPA} />

            <div className="newsletter">
                {variant === 'alpha' && isDesktop ? (
                    <div className="newsletter_head">
                        <Icon icon={NewsletterIcon} className="newsletter_head_icon" viewBox="0 0 50 60" />
                        <div className="newsletter_head_title_wrapper">
                            <TitleTag className="newsletter_head_title">Primaj zena.hr newsletter</TitleTag>
                            <div className="newsletter_head_description">
                                Novosti na portalu direktno u tvoj inbox. Ne propusti ništa, nikad.
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="newsletter_head">
                        <div className="newsletter_head_title_wrapper">
                            <Icon icon={NewsletterIcon} className="newsletter_head_icon" viewBox="0 0 50 60" />
                            <TitleTag className="newsletter_head_title">Primaj zena.hr newsletter</TitleTag>
                        </div>
                        <div className="newsletter_head_description">
                            Novosti na portalu direktno u tvoj inbox. Ne propusti ništa, nikad.
                        </div>
                    </div>
                )}

                <Formik innerRef={newsletterForm} onSubmit={onSubmit} initialValues={{ email: '' }}>
                    {({ isSubmitting }) => (
                        <Form className="newsletter_form">
                            <div className="newsletter_form_input">
                                <Field name="email">
                                    {({ field }) => (
                                        <input
                                            className="newsletter_form_input_item"
                                            placeholder="Upiši svoju email adresu..."
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="newsletter_form_submit">
                                <Button
                                    type="submit"
                                    title="Prijavi se"
                                    disabled={isSubmitting}
                                    className="newsletter_form_submit_button"
                                    variant={buttonVariants.BETA}
                                    iconComponent={
                                        <Icon icon={UnionIcon} className="newsletter_form_submit_button_icon" />
                                    }>
                                    Prijavi se
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

                {message && (
                    <div className={message.includes('uspješno') ? 'newsletter_form_success' : 'newsletter_form_fail'}>
                        {message}
                    </div>
                )}
            </div>
        </NewsletterStyled>
    )
}

const newsletterVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Newsletter.propTypes = {
    className: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    variant: PropTypes.oneOf([...Object.values(newsletterVariants)])
}

Newsletter.defaultProps = {
    className: undefined,
    titleTagComponent: 'h3',
    variant: newsletterVariants.ALPHA
}

export { newsletterVariants }

export default Newsletter
