import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ isEmpty, theme }) => ({
    ...(isEmpty && { height: toRem(128) }),
    width: '100%',
    padding: toRems([30, 0]),
    [theme.breakpoints.up('md')]: {
        padding: toRems([60, 0])
    },
    '& .divider_graphic': {
        width: '100%'
    }
})

export default style
