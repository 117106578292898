import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    margin: 'auto',
    padding: toRem(25),
    [theme.breakpoints.up('md')]: {
        padding: 0
    },
    '& .campaign': {
        '&_heading': {
            color: theme.colors.accent,
            width: '100%',
            maxWidth: toRem(960),
            textAlign: 'center',
            margin: 'auto',
            marginBottom: toRem(50),
            fontSize: toRem(30),
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(40)
            }
        },
        '&_swiper': {
            width: '100%',
            maxWidth: toRem(960),
            '.swiper-pagination': {
                bottom: 0
            }
        },
        '&_item': {
            marginBottom: toRem(50),
            '&_text': {
                padding: toRems([30, 10, 10, 0]),
                fontSize: toRem(16),
                textAlign: 'center',
                color: theme.colors.text.default
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '&:hover .campaign_item_text': {
                color: theme.colors.text.accent
            }
        }
    }
})

export default style
