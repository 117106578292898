import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, size, decoration, titleHref, isItalic }) => {
    const config = {
        small: {
            xs: {
                fontSize: toRem(22),
                size: {
                    width: toRem(60),
                    height: toRem(60),
                    margin: toRems([0, -40, 0, -25])
                }
            },
            md: {
                fontSize: toRem(48),
                size: {
                    width: toRem(110),
                    height: toRem(110),
                    margin: toRems([0, -80, 0, -25])
                }
            }
        },
        normal: {
            xs: {
                fontSize: toRem(24),
                size: {
                    width: toRem(60),
                    height: toRem(60),
                    margin: toRems([0, -40, 0, -25])
                }
            },
            md: {
                fontSize: toRem(56),
                size: {
                    width: toRem(110),
                    height: toRem(110),
                    margin: toRems([0, -80, 0, -25])
                }
            }
        },
        large: {
            xs: {
                fontSize: toRem(28),
                size: {
                    width: toRem(60),
                    height: toRem(60),
                    margin: toRems([0, -40, 0, -25])
                }
            },
            md: {
                fontSize: toRem(64),
                size: {
                    width: toRem(110),
                    height: toRem(110),
                    margin: toRems([0, -80, 0, -25])
                }
            }
        }
    }

    return {
        display: 'flex',
        alignItems: 'center',
        margin: toRems([10, 0]),
        [theme.breakpoints.up('md')]: {
            padding: titleHref ? toRem(25) : 0
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: titleHref ? toRem(35) : toRem(10)
        },
        '& .title_decoration': {
            marginRight: toRem(10)
        },
        '& .title_wrapper': {
            display: 'flex',
            alignItems: 'center',
            ...(decoration === 'alpha' && {
                '&:before': {
                    content: '""',
                    flexShrink: 0,
                    margin: config[size]?.xs.size.margin,
                    width: config[size]?.xs.size.width,
                    height: config[size]?.xs.size.height,
                    border: `1px solid ${theme.colors.heading.basic.text.accent}`,
                    [theme.breakpoints.up('md')]: {
                        margin: config[size]?.md.size.margin,
                        width: config[size]?.md.size.width,
                        height: config[size]?.md.size.height
                    }
                }
            })
        },
        '& .title': {
            // marginLeft: toRem(10),
            ...(isItalic && {
                fontFamily: theme.typography.fontFamilyAlt,
                fontStyle: theme.typography.font.style.italic,
                fontWeight: theme.typography.fontWeight.medium
            }),
            background: theme.colors.background.body.default,
            fontSize: config[size]?.xs.fontSize,
            [theme.breakpoints.up('md')]: {
                // marginLeft: toRem(20),
                fontSize: config[size]?.md.fontSize
            },
            transition: 'color 0.3s'
        },
        '& .title:hover': {
            textDecoration: titleHref ? 'underline' : 'none',
            color: titleHref ? theme.colors.text.accent : theme.colors.text.default,
            cursor: titleHref ? 'pointer' : 'auto'
        }
    }
}

export default style
