/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import SwiperCore, { Autoplay, Grid, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { RenderInView } from '@hmn/rtl-web-core/components/RenderInView'
import { useBreakpoints, useData, useEnvironment, useGTM, useInnerHtml } from '@hmn/rtl-web-core/hooks'
import { returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { ArrowLeftIcon } from '../Icon'
import { Image, imageRatioVariants } from '../Image'
import { Link } from '../Link'
import { PaginationButton } from '../Pagination/components'
import { ListalicaCard } from './components/Card'
import styles from './ListalicaProizvoda.style'

SwiperCore.use([Autoplay, Navigation])
const ListalicaProizvodaStyled = styled.div(props => ({ ...styles(props) }))

const parseLoadedData = data => ({
    title: data?.title,
    items: data?.extended_attributes?.product_galleries_products,
    showLogo: !data?.extended_attributes?.product_galleries_show_title,
    logo: data?.image,
    titleUrl: data?.extended_attributes?.product_galleries_url,
    disclaimer: data?.extended_attributes?.product_galleries_disclaimer
})

const listalicaProizvodaSourceVariants = Object.freeze({
    ITEMS: 'items', // uses regular props, not response
    NET_WEBSHOP: 'net-webshop',
    SPAR: 'spar',
    KONZUM: 'konzum'
})

const getProductGalleryId = (variant, isProductionEnv = true) => {
    switch (variant) {
        case listalicaProizvodaSourceVariants.NET_WEBSHOP:
            return isProductionEnv ? '85d25420-be00-11eb-af0e-6ef79b5c6087' : '56e5c3aa-7818-11eb-9e29-021e9d398172'
        // case listalicaProizvodaSourceVariants.KONZUM:
        //     return isProductionEnv ? '5234efd4-ce94-11eb-a4e9-560801fed869' : 'a616e82c-a9ab-11eb-8f70-b68af4b620f7'
        // case listalicaProizvodaSourceVariants.SPAR:
        //     return isProductionEnv ? 'b5b7334e-2765-11ec-bf01-56fed9847f00' : 'a616e82c-a9ab-11eb-8f70-b68af4b620f7'
        case listalicaProizvodaSourceVariants.ITEMS:
        default:
            return null
    }
}

function ListalicaProizvodaAntiClsFrame({ children }) {
    return (
        <AntiClsFrame minHeightXs={580} minHeightMd={580}>
            {children}
        </AntiClsFrame>
    )
}

function ListalicaProizvoda({
    titleTagComponent: TitleTag,
    title: title0,
    showLogo: showLogo0,
    logoImage,
    items: items0,
    titleUrl: titleUrl0,
    disclaimer: disclaimer0,
    className,
    isWidget,
    sourceVariant
}) {
    const { sendDataToGTM } = useGTM()
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const swiperComponent = useRef()

    const slideLeft = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slidePrev()
        }
    }

    const slideRight = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slideNext()
        }
    }

    const { ref, inView } = useInView({ initialInView: false, threshold: 0.9, triggerOnce: true })

    const { isProduction } = useEnvironment()

    const galleryId = useMemo(() => getProductGalleryId(sourceVariant, isProduction), [isProduction, sourceVariant])

    const { data: loadedData } = useData({
        resource: `entity/${galleryId}`,
        enabled: !!galleryId && sourceVariant !== listalicaProizvodaSourceVariants.ITEMS,
        options: { preview: true },
        errorHandlers: returnEmptyOn4xx
    })

    /**
     * Depending on sourceVariant it returns loadedData with props where loadedData is missing
     *  or just the props when sourceVariant is not provided (not is ITEMS).
     * This approach uses functional style where the data is not mutated.
     */
    const { title, items, titleUrl, disclaimer, logo, showLogo } = useMemo(() => {
        if (typeof loadedData !== 'undefined') {
            const parsedData = parseLoadedData(loadedData)

            return {
                title: parsedData?.title || title0,
                items: parsedData?.items || items0,
                titleUrl: parsedData?.titleUrl || titleUrl0,
                disclaimer: parsedData?.disclaimer || disclaimer0,
                logo: logoImage || parsedData?.logo,
                showLogo: parsedData?.showLogo || showLogo0
            }
        }

        return {
            title: title0,
            items: items0,
            titleUrl: titleUrl0,
            disclaimer: disclaimer0,
            logo: logoImage,
            showLogo: showLogo0
        }
    }, [loadedData, title0, items0, titleUrl0, disclaimer0, logoImage, showLogo0])

    useEffect(() => {
        if (inView) {
            sendDataToGTM({
                event: 'gtm.View',
                eventCategory: isWidget ? 'Listalica - naslovnica' : 'Listalica - članak',
                eventAction: `View ${title}`,
                eventLabel: `${title} - ${titleUrl}`
            })
        }
    }, [inView, isWidget, title, titleUrl, sendDataToGTM])

    const gtmDataHandler = gtmData => {
        sendDataToGTM(gtmData)
    }

    const titleHtmlProps = useInnerHtml(title)

    const { data: image = logo } = useData({
        resource: `image/${logo?.id}`,
        enabled: !!logo?.id && !logoImage?.id,
        errorHandlers: returnEmptyOn4xx
    })

    const autoplay = useMemo(() => {
        if ((isDesktop && items?.length > 4) || (!isDesktop && items?.length > 1)) {
            return {
                delay: 4000,
                disableOnInteraction: false
            }
        }

        return undefined
    }, [isDesktop, items?.length])

    if (!items) {
        return <ListalicaProizvodaAntiClsFrame />
    }

    const currentDate = dayjs()
    const activeItems = items.filter(item =>
        item?.schedule ? currentDate.isAfter(item.show_from) && currentDate.isBefore(item.show_until) : true
    )

    return (
        <RenderInView>
            <ListalicaProizvodaStyled className={className} ref={ref}>
                {showLogo && (
                    <ConditionalWrapper
                        condition={!!titleUrl}
                        Wrapper={Link}
                        href={titleUrl}
                        isExternal
                        as={titleUrl}
                        title={title}>
                        <Image
                            classNameProgressive="listalicaProizvoda_logo"
                            image={image}
                            alt={title}
                            variation={imageRatioVariants.CUSTOM_ORIGINAL}
                        />
                    </ConditionalWrapper>
                )}
                {!showLogo && title && (
                    <ConditionalWrapper
                        condition={!!titleUrl}
                        Wrapper={Link}
                        href={titleUrl}
                        isExternal
                        as={titleUrl}
                        title={title}>
                        <TitleTag className="listalicaProizvoda_title" {...titleHtmlProps} />
                    </ConditionalWrapper>
                )}
                {activeItems?.length > 3 && (
                    <Media greaterThan="sm" scaleDown>
                        <div className="listalicaProizvoda_slider">
                            <PaginationButton
                                icon={ArrowLeftIcon}
                                className="listalicaProizvoda_button"
                                onClick={slideLeft}
                            />
                            <PaginationButton
                                icon={ArrowLeftIcon}
                                rotate={180}
                                className="listalicaProizvoda_button"
                                onClick={slideRight}
                            />
                        </div>
                    </Media>
                )}
                <Swiper
                    ref={swiperComponent}
                    className="listalicaProizvoda_swiper"
                    spaceBetween={isDesktop ? '2%' : 25}
                    grid={isDesktop ? { rows: 1 } : undefined}
                    slidesPerView={isDesktop ? 3 : 'auto'}
                    loop={isDesktop ? activeItems?.length > 3 : activeItems?.length > 1}
                    autoplay={autoplay}
                    modules={isDesktop ? [Grid] : []}
                    navigation={{
                        prevEl: '.swiperNav_prev',
                        nextEl: '.swiperNav_next'
                    }}>
                    {activeItems?.map(item => (
                        <SwiperSlide key={uid(item.id || item)} className="listalicaProizvoda_item">
                            <ListalicaCard
                                onClick={() =>
                                    gtmDataHandler({
                                        event: 'gtm.Click',
                                        eventCategory: isWidget ? 'Listalica - naslovnica' : 'Listalica - članak',
                                        eventAction: `Click ${title}`,
                                        eventLabel: `${title} - ${titleUrl}`
                                    })
                                }
                                buttonLabel={item.button}
                                buttonHref={item.url}
                                description={item.description}
                                price={item.price}
                                oldPrice={item.priceOld}
                                priceEuro={item.priceEuro}
                                priceOldEuro={item.priceOldEuro}
                                title={item.title}
                                imageId={item.image.id}
                                imageLabel={item.imageLabel}
                                imageView={item.imageView}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {disclaimer && <div className="listalicaProizvoda_disclaimer">{disclaimer}</div>}
            </ListalicaProizvodaStyled>
        </RenderInView>
    )
}
ListalicaProizvoda.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    showLogo: PropTypes.bool,
    logoImage: PropTypes.shape({
        id: PropTypes.string
    }),
    titleTagComponent: PropTypes.elementType,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    titleUrl: PropTypes.string,
    isWidget: PropTypes.bool,
    disclaimer: PropTypes.string,
    sourceVariant: PropTypes.oneOf([...Object.values(listalicaProizvodaSourceVariants)])
}

ListalicaProizvoda.defaultProps = {
    className: undefined,
    title: 'Net.hr webshop',
    showLogo: undefined,
    logoImage: undefined,
    titleTagComponent: 'h3',
    items: undefined,
    titleUrl: undefined,
    isWidget: false,
    disclaimer: undefined,
    sourceVariant: listalicaProizvodaSourceVariants.ITEMS
}

export { listalicaProizvodaSourceVariants, ListalicaProizvodaAntiClsFrame }

export default withErrorBoundary(ListalicaProizvoda, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ListalicaProizvoda]: ', error, componentStack)
    }
})
