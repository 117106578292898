import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useBreakpoints, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { articleCardDecorationVariants, articleCardVariants } from '../Article/components/Card'
import { Frame, Graphic, graphicVariants } from '../Decoration/components'
import { HeadingLabel } from '../Heading/components'
import { Image, imageRatioVariants } from '../Image'
import styles from './Special.style'

const SpecialStyled = styled.section(props => ({ ...styles(props) }))
function Special({
    children,
    label,
    title,
    titleTagComponent: TitleTag,
    lead,
    image,
    imageWidth,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight,
    alt,
    className,
    ...rest
}) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])
    const labelHtmlProps = useInnerHtml(label)
    const titleHtmlProps = useInnerHtml(title)
    const leadHtmlProps = useInnerHtml(lead)
    if (!itemElements.length) {
        return null
    }
    return (
        <SpecialStyled className={className} {...rest}>
            <div className="special_inner">
                <div className="special_list">
                    {image && (
                        <Media greaterThan="sm" scaleDown className="special_image">
                            <Image
                                variation={imageRatioVariants.CUSTOM_4x5}
                                image={image}
                                width={imageWidth}
                                hideAuthor
                                // height={imageHeight}
                                alt={alt || title}
                                fullHeight
                            />
                            <Frame className="special_frame" />
                            <Graphic
                                className="special_graphic"
                                variant={graphicVariants.EPSILON}
                                widthXs={132}
                                widthMd={132}
                                heightXs={112}
                                heightMd={112}
                            />
                        </Media>
                    )}
                    <div className="special_head">
                        {label && (
                            <HeadingLabel>
                                <span {...labelHtmlProps} />
                            </HeadingLabel>
                        )}
                        {title && <TitleTag className="special_headTitle" {...titleHtmlProps} />}
                        {lead && <div className="special_headLead" {...leadHtmlProps} />}
                    </div>
                    {itemElements?.slice(0, 3).map((child, index) => (
                        <div
                            key={uid(child?.props?.item?.id || index)}
                            className={clsx('special_item', index === 0 && 'isFirst')}>
                            {cloneElement(child, {
                                imageWidth: 550,
                                imageHeight: 550,
                                variant: index === 2 && isDesktop ? articleCardVariants.ETA : articleCardVariants.BETA,
                                decorationVariant: index === 2 && isDesktop ? articleCardDecorationVariants.BETA : null
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </SpecialStyled>
    )
}

Special.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt: PropTypes.string
}
Special.defaultProps = {
    className: undefined,
    label: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    image: undefined,
    imageWidth: 650,
    imageHeight: 812,
    alt: undefined
}

export default withErrorBoundary(Special, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Special]: ', error, componentStack)
    }
})
