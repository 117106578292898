/* eslint-disable quote-props, no-dupe-keys, max-len */

import { toRem, toRems } from '../../../../helpers'

const style = ({ theme }) => {
    const config = {
        sm: {
            graphic: {
                height: toRem(36),
                right: toRem(-20)
            },
            imageWrapper: {
                height: toRem(200),
                label: {
                    fontSize: toRem(22),
                    padding: toRems([5, 4])
                }
            },
            title: {
                fontSize: toRem(18),
                lineHeight: 1.4,
                minHeight: toRem(50)
            },
            priceWrapper: {
                minHeight: toRem(38),
                price: {
                    fontSize: toRem(24),
                    currency: {
                        fontSize: toRem(18)
                    }
                },
                oldPrice: {
                    fontSize: toRem(16)
                },
                priceSm: {
                    fontSize: toRem(11),
                    currency: {
                        fontSize: toRem(11)
                    }
                }
            },
            description: {
                minHeight: toRem(23),
                fontSize: toRem(14)
            }
        },
        md: {
            graphic: {
                height: toRem(40),
                right: toRem(-20)
            },
            imageWrapper: {
                height: toRem(300),
                label: {
                    fontSize: toRem(24),
                    padding: toRems([8, 8, 4, 6])
                }
            },
            title: {
                fontSize: toRem(24),
                lineHeight: 1.6,
                minHeight: toRem(76)
            },
            priceWrapper: {
                minHeight: toRem(51),
                price: {
                    fontSize: toRem(32),
                    currency: {
                        fontSize: toRem(20)
                    }
                },
                oldPrice: {
                    fontSize: toRem(16)
                },
                priceSm: {
                    fontSize: toRem(18),
                    currency: {
                        fontSize: toRem(12)
                    }
                }
            },
            description: {
                minHeight: toRem(23),
                fontSize: toRem(14)
            }
        }
    }

    return {
        '&>a.fullHeight': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '.listalicaCard': {
            '&_graphic': {
                position: 'absolute',
                zIndex: theme.zIndex.over,
                top: toRem(15),
                height: config.sm.graphic.height,
                right: config.sm.graphic.right,
                [theme.breakpoints.up('md')]: {
                    top: toRem(30),
                    height: config.md.graphic.height,
                    right: config.md.graphic.right
                }
            },
            '&_imageWrapper': {
                '&_label': {
                    letterSpacing: toRem(1),
                    fontFamily: theme.typography.fontFamilyAlt,
                    fontStyle: theme.typography.font.style.italic,
                    fontWeight: theme.typography.fontWeight.regular,
                    color: theme.colors.text.white,
                    backgroundColor: theme.colors.zenaLightPink,
                    fontSize: config.sm.imageWrapper.label.fontSize,
                    padding: config.sm.imageWrapper.label.padding,
                    position: 'absolute',
                    zIndex: theme.zIndex.over,
                    right: 0,
                    top: toRem(15),
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.imageWrapper.label.fontSize,
                        padding: config.md.imageWrapper.label.padding,
                        top: toRem(30)
                    }
                }
            },
            '&_title': {
                marginTop: toRem(20),
                fontFamily: theme.typography.fontFamilyAlt,
                fontStyle: theme.typography.font.style.italic,
                fontWeight: theme.typography.fontWeight.regular,
                color: theme.colors.text.default,
                fontSize: config.sm.title.fontSize,
                lineHeight: config.sm.title.lineHeight,
                [theme.breakpoints.up('md')]: {
                    fontSize: config.md.title.fontSize,
                    lineHeight: config.md.title.lineHeight
                }
            },
            '&_priceWrapper': {
                minHeight: config.sm.priceWrapper.minHeight,
                [theme.breakpoints.up('md')]: {
                    minHeight: config.md.priceWrapper.minHeight
                },
                '&_price': {
                    position: 'relative',
                    fontFamily: theme.typography.fontFamilyAlt,
                    fontStyle: theme.typography.font.style.italic,
                    letterSpacing: toRem(1),
                    lineHeight: 1.6,
                    color: theme.colors.zenaLightPink,
                    fontSize: config.sm.priceWrapper.price.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.priceWrapper.price.fontSize
                    },
                    '&_sm': {
                        fontSize: config.sm.priceWrapper.priceSm.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.priceSm.fontSize
                        }
                    },
                    '&_currency': {
                        position: 'absolute',
                        bottom: 0,
                        marginLeft: toRem(5),
                        letterSpacing: toRem(0),
                        lineHeight: 2.5,
                        fontSize: config.sm.priceWrapper.price.currency.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.price.currency.fontSize
                        },
                        '&_sm': {
                            fontSize: config.sm.priceWrapper.priceSm.currency.fontSize,
                            [theme.breakpoints.up('md')]: {
                                fontSize: config.md.priceWrapper.priceSm.currency.fontSize
                            }
                        }
                    }
                },
                '&_oldPrice': {
                    marginLeft: toRem(35),
                    letterSpacing: toRem(1),
                    lineHeight: 2,
                    color: theme.colors.grey500,
                    textDecoration: 'line-through',
                    fontSize: config.sm.priceWrapper.oldPrice,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.priceWrapper.oldPrice
                    },
                    '&_sm': {
                        fontSize: config.sm.priceWrapper.priceSm.fontSize,
                        [theme.breakpoints.up('md')]: {
                            fontSize: config.md.priceWrapper.priceSm.fontSize
                        }
                    }
                }
            },
            '&_description': {
                margin: toRems([10, 0, 30, 0]),
                lineHeight: 1.6,
                color: theme.colors.grey500,
                fontSize: config.sm.description.fontSize,
                minHeight: config.sm.description.minHeight,
                [theme.breakpoints.up('md')]: {
                    fontSize: config.md.description.fontSize,
                    minHeight: config.md.description.minHeight
                },
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '3'
            }
        }
    }
}

export default style
