import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints, useWindow } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../ConditionalWrapper'
import styles from './SliderWrapper.style'

const SliderWrapperStyled = styled.div(props => ({ ...styles(props) }))

// @TODO: implement Slider component instead of placeholder
function PlaceholderComponent({ children }) {
    return <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>{children}</div>
}

function SliderWrapper({ className, children, ...rest }) {
    const { width: windowWidth } = useWindow()
    const [scroll, setScroll] = useState(false)
    const [isDesktop] = useBreakpoints('md')
    const wrapperRef = useRef()
    const contentRef = useRef()
    useEffect(() => {
        const { width: wrapperWidth } = wrapperRef?.current?.getBoundingClientRect() || {}
        const { width: contentWidth } = contentRef?.current?.getBoundingClientRect() || {}
        if (isDesktop || (scroll && wrapperWidth >= contentWidth)) {
            setScroll(false)
        } else if (!scroll && wrapperWidth < contentWidth) {
            setScroll(true)
        }
    }, [windowWidth, children, isDesktop, scroll])

    return (
        <SliderWrapperStyled isDesktop={isDesktop} {...rest}>
            <div className="sliderWrapper" ref={wrapperRef}>
                <ConditionalWrapper condition={scroll} Wrapper={PlaceholderComponent}>
                    <div ref={contentRef} className={`sliderWrapper_inner ${className}`}>
                        {children}
                    </div>
                </ConditionalWrapper>
            </div>
        </SliderWrapperStyled>
    )
}

SliderWrapper.propTypes = {
    className: PropTypes.string
}

SliderWrapper.defaultProps = {
    className: undefined
}

export default withErrorBoundary(SliderWrapper, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SliderWrapper]: ', error, componentStack)
    }
})
