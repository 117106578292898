import { toRem, toRems } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    position: 'relative',
    margin: `${toRem(20)} calc(50% - 50vw)`,
    width: '100vw',
    [theme.breakpoints.up('md')]: {
        marginTop: toRem(45)
    },
    '& .special_inner': {
        position: 'relative',
        backgroundColor: theme.colors.background.body.default
    },
    '& .special_list': {
        maxWidth: toRem(1250),
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
            padding: toRems([0, 25, 75, 25]),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: toRem(100),
            alignItems: 'center'
        }
    },
    '& .special_image': {
        position: 'relative',
        gridRow: '1/3',
        alignSelf: 'flex-start'
    },
    '& .special_frame': {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%'
    },
    '& .special_graphic': {
        position: 'absolute',
        top: toRem(100),
        right: 0,
        [theme.breakpoints.up('md')]: {
            right: toRem(-50)
        }
    },
    '& .special_head': {
        padding: toRems([38, 25]),
        [theme.breakpoints.up('lg')]: {
            padding: toRems([30, 0])
        }
    },
    '& .special_headTitle': {
        margin: toRems([12, 0, 15]),
        color: theme.colors.text.accent,
        fontSize: toRem(28),
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            margin: toRems([20, 0, 10]),
            fontSize: toRem(64)
        }
    },
    '& .special_headLead': {
        paddingBottom: toRem(30),
        color: theme.colors.text.default,
        fontSize: toRem(16),
        '& > *': {
            fontSize: 'inherit'
        },
        p: {
            '&:last-of-type': {
                margin: 0
            }
        },
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(18)
        }
    },
    '& .special_item': {
        '&.isFirst': {
            [theme.breakpoints.up('lg')]: {
                padding: toRems([20, 30, 60, 0])
            }
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: toRem(40)
        }
    }
})

export default style
