/* eslint-disable jsx-a11y/alt-text */
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Link } from '../Link'
import styles from './Campaign.style'

SwiperCore.use([Autoplay, Pagination])
const CampaignStyled = styled.div(props => ({ ...styles(props) }))

const slides = [
    {
        image: '/images/campaign/at_2020_1.jpg',
        text: 'Svježi zrak, predivna panorama, škripanje snijega pod skijama - sanjarimo o zimi',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543595265&iu=/2936024'
    },
    {
        image: '/images/campaign/salzburg_2020_1.jpg',
        text: 'Panorama Salzburga očarava posjetitelje u svako doba godine, a osobito zimi',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543596054&iu=/2936024'
    },
    {
        image: '/images/campaign/zillertal_2020_1.jpg',
        text: 'Dječje zimske radosti i zajednički odmor na snijegu u dolini Zillertal',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543596531&iu=/2936024'
    },
    {
        image: '/images/campaign/at_2020_2.jpg',
        text: 'Veselje osvajanja planinskih vrhova',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543595265&iu=/2936024'
    },
    {
        image: '/images/campaign/salzburg_2020_2.jpg',
        text: 'Teško da ijedan grad nudi toliko zimske romantike kao Salzburg',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543596054&iu=/2936024'
    },
    {
        image: '/images/campaign/zillertal_2020_2.jpg',
        text: 'Prvi snijeg na snježnim padinama Zillertala je neopisiv užitak',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543596531&iu=/2936024'
    },
    {
        image: '/images/campaign/at_2020_4.jpg',
        text: 'Uživanje na suncu među alpskim vrhovima',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543595265&iu=/2936024'
    },
    {
        image: '/images/campaign/at_2020_3.jpg',
        text: 'Pogled na snijegom prekrivene krovove i snježne vrhove iz tople sobe',
        href: 'https://pubads.g.doubleclick.net/gampad/clk?id=5543595265&iu=/2936024'
    }
]

function Campaign({ className, ...rest }) {
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    return (
        <CampaignStyled className={className} {...rest}>
            <img
                src="https://track.adform.net/adfserve/?bn=41888362;1x1inv=1;srctype=3;ord=[timestamp]"
                border="0"
                width="1"
                height="1"
            />
            <img
                src="https://track.adform.net/adfserve/?bn=41888375;1x1inv=1;srctype=3;ord=[timestamp]"
                border="0"
                width="1"
                height="1"
            />
            <img
                src="https://track.adform.net/adfserve/?bn=41888394;1x1inv=1;srctype=3;ord=[timestamp]"
                border="0"
                width="1"
                height="1"
            />
            <h1 className="campaign_heading">Odmor u Austriji dobar razlog za gledati naprijed</h1>
            <Swiper
                className="campaign_swiper"
                spaceBetween={50}
                slidesPerView={isDesktop ? 3 : 2}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false
                }}>
                {slides.map((slide, index) => (
                    <SwiperSlide key={uid(slide.image || index)} className="campaign_item">
                        <Link className="campaing_item_link" href={slide.href}>
                            <img src={slide.image} alt={slide.image} />
                            <h6 className="campaign_item_text">{slide.text}</h6>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </CampaignStyled>
    )
}

Campaign.propTypes = {
    className: PropTypes.string
}

Campaign.defaultProps = {
    className: undefined
}

export default Campaign
