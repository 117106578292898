import { Fragment } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import useCurrentPageNumber from '@hmn/rtl-web-core/hooks/useCurrentPageNumber'

import { staticAds } from '@hmn/rtl-zena-ui/components/Ad/config/general'
import AdSlot from '@hmn/rtl-zena-ui/components/Ad/ZenaSlot.component'
import { CategoryHead } from '@hmn/rtl-zena-ui/components/Category/components'
import { EntityCard } from '@hmn/rtl-zena-ui/components/EntityCard'
import { Pagination } from '@hmn/rtl-zena-ui/components/Pagination'

function CategoryListingDefault({
    articles,
    insertAds,
    getRowInfo,
    getArticleVariant,
    getDecorationVariant,
    getLayoutGridSize,
    getImageVariation,
    category,
    perPage,
    breadcrumbs,
    parentCategoryLink
}) {
    const uid = useUIDSeed()

    const currentPage = useCurrentPageNumber()

    const { billboardTop } = staticAds

    return (
        <Row variant="category.main">
            <Column variant="category.head">
                <CategoryHead label={breadcrumbs} labelLink={parentCategoryLink} title={category?.title} />
            </Column>
            <Column variant="category.ads.billboard">
                <AdSlot {...billboardTop} />
            </Column>
            <Column variant="category.listing">
                {!!articles?.length &&
                    articles.map((item, index) => {
                        // @TODO: implement conditionalInsert here
                        const { index: rowIndex, lastInRow: isLastInRow } = getRowInfo(index)
                        const offsetRowIndex = rowIndex + 1 - insertAds[0].offset
                        const rowMatchesAdPattern = offsetRowIndex % insertAds[0].every === 0 && offsetRowIndex >= 0
                        const insertAdIndex = Math.floor((rowIndex - insertAds[0].offset) / insertAds[0].every)

                        return (
                            <Fragment key={uid(item?.id || index)}>
                                <Column
                                    className={index === 6 ? `u-alignSelf_center` : null}
                                    key={uid(item?.id || index)}
                                    variant={
                                        index === 0
                                            ? 'category.listing.itemLarge'
                                            : `grid.12.col.${getLayoutGridSize(index)}x1`
                                    }>
                                    <EntityCard
                                        item={item}
                                        imageVariation={getImageVariation(index)}
                                        cardVariant={getArticleVariant(index)}
                                        cardDecorationVariant={getDecorationVariant(index)}
                                        imageLazyLoad={index > 2}
                                        imagePreload={index === 0}
                                    />
                                </Column>
                                {isLastInRow && rowMatchesAdPattern && insertAdIndex < insertAds[0].elements.length
                                    ? insertAds[0].elements[insertAdIndex]
                                    : null}
                            </Fragment>
                        )
                    })}
                <Row variant="category.loadMore">
                    <Column>
                        <Pagination
                            pageTotal={(articles?.[0]?.totalItems || 0) / perPage}
                            selected={currentPage}
                            paginationColor={category?.extended_attributes?.category_color}
                        />
                    </Column>
                </Row>
            </Column>
        </Row>
    )
}

CategoryListingDefault.propTypes = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            totalItems: PropTypes.number
        })
    ),
    category: PropTypes.shape({
        title: PropTypes.string,
        extended_attributes: PropTypes.shape({
            category_color: PropTypes.string
        })
    }),
    perPage: PropTypes.number.isRequired,
    getRowInfo: PropTypes.func.isRequired,
    getArticleVariant: PropTypes.func.isRequired,
    getDecorationVariant: PropTypes.func.isRequired,
    getLayoutGridSize: PropTypes.func.isRequired,
    getImageVariation: PropTypes.func.isRequired,
    insertAds: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            every: PropTypes.number,
            elements: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.elementType),
                PropTypes.arrayOf(PropTypes.shape({}))
            ])
        })
    ),
    breadcrumbs: PropTypes.string,
    parentCategoryLink: PropTypes.string.isRequired
}

CategoryListingDefault.defaultProps = {
    articles: [],
    category: undefined,
    insertAds: [],
    breadcrumbs: undefined
}

export default CategoryListingDefault
