export const parseStringForZoneName = str =>
    str
        // eslint-disable-next-line no-useless-escape
        .split(/[ -,_;/\\|<>\-]/g)
        .filter(Boolean)
        .map(part => part[0].toUpperCase() + part.slice(1))
        .join('')

export const getWidgetZoneName = positionConfig =>
    parseStringForZoneName(`Widget ${positionConfig?.adUnit || positionConfig?.header}`)
