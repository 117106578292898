import dynamic from 'next/dynamic'

import { ListalicaProizvodaAntiClsFrame } from './ListalicaProizvoda.component'

export {
    listalicaProizvodaSourceVariants,
    default as ListalicaProizvoda,
    ListalicaProizvodaAntiClsFrame
} from './ListalicaProizvoda.component'

const DynamicListalicaProizvoda = dynamic(() => import('./ListalicaProizvoda.component'), {
    ssr: false,
    loading: () => <ListalicaProizvodaAntiClsFrame />
})

export { DynamicListalicaProizvoda }
