import { toRem, toRems } from '../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        sm: {
            fontSize: toRem(22)
        },
        md: {
            variant: {
                alpha: {
                    head: {
                        display: 'flex'
                    },
                    icon: {
                        width: toRem(70),
                        height: toRem(70),
                        paddingTop: toRem(10),
                        marginRight: toRem(20)
                    },
                    description: {
                        marginTop: 0
                    },
                    form: {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: toRem(15)
                    },
                    input: {
                        height: toRem(60),
                        width: '60%'
                    },
                    button: {
                        height: toRem(60),
                        marginTop: 0
                    }
                },
                beta: {
                    maxWidth: toRem(350),
                    margin: 'auto',
                    icon: {
                        width: toRem(55),
                        height: toRem(55),
                        paddingTop: toRem(5),
                        marginRight: toRem(10),
                        marginLeft: toRem(-10)
                    },
                    titleWrapper: {
                        display: 'flex'
                    },
                    description: {
                        marginTop: toRem(20)
                    },
                    form: {
                        marginTop: toRem(30)
                    },
                    input: {
                        height: toRem(50),
                        width: '100%'
                    },
                    button: {
                        width: '100%',
                        marginTop: toRem(30),
                        height: toRem(50)
                    }
                }
            }
        }
    }
    return {
        // display: 'flex',
        // flexDirection: 'center',
        position: 'relative',
        backgroundColor: theme.colors.background.body.default,
        width: '100%',
        maxWidth: config.md.variant[variant]?.maxWidth,
        margin: config.md.variant[variant]?.margin,
        border: `1px solid ${theme.colors.border.default}`,
        padding: toRems([40, 20, 40, 20]),
        [theme.breakpoints.up('md')]: {
            padding: toRems([51, 51, 51, 51])
        },
        '& .newsletter': {
            zIndex: theme.zIndex.over,
            '&_head': {
                [theme.breakpoints.up('md')]: {
                    display: config.md.variant[variant]?.head?.display
                },
                '&_icon': {
                    [theme.breakpoints.down('md')]: {
                        marginLeft: toRem(-10)
                    },
                    width: toRem(55),
                    height: toRem(55),
                    paddingTop: toRem(5),
                    marginRight: toRem(5),
                    [theme.breakpoints.up('md')]: {
                        width: config.md.variant[variant]?.icon?.width,
                        height: config.md.variant[variant]?.icon?.height,
                        paddingTop: config.md.variant[variant]?.icon?.paddingTop,
                        marginRight: config.md.variant[variant]?.icon?.marginRight,
                        marginLeft: config.md.variant[variant]?.icon?.marginLeft
                    }
                },
                '&_title_wrapper': {
                    [theme.breakpoints.down('sm')]: {
                        display: 'flex'
                    },
                    [theme.breakpoints.up('md')]: {
                        display: config.md.variant[variant]?.titleWrapper?.display
                    }
                },
                '&_title': {
                    fontFamily: theme.typography.fontFamilyAlt,
                    fontStyle: theme.typography.font.style.italic,
                    fontWeight: theme.typography.fontWeight.medium,
                    color: theme.colors.text.default,
                    fontSize: toRem(24),
                    lineHeight: 1.2,
                    [theme.breakpoints.up('md')]: {
                        lineHeight: 1.6
                    }
                },
                '&_description': {
                    fontWeight: 500,
                    lineHeight: 1.8,
                    fontSize: toRem(16),
                    marginTop: toRem(20),
                    [theme.breakpoints.up('md')]: {
                        fontSize: toRem(18),
                        marginTop: config.md.variant[variant]?.description?.marginTop
                    }
                }
            },
            '&_form': {
                display: 'flex',
                flexDirection: 'column',
                marginTop: toRem(25),
                [theme.breakpoints.up('md')]: {
                    flexDirection: config.md.variant[variant]?.form?.flexDirection,
                    justifyContent: config.md.variant[variant]?.form?.justifyContent,
                    marginTop: config.md.variant[variant]?.form?.marginTop
                },
                '&_input': {
                    borderBottom: `2px solid ${theme.colors.border.accent}`,
                    width: '100%',
                    height: toRem(50),
                    [theme.breakpoints.up('md')]: {
                        width: config.md.variant[variant]?.input?.width,
                        height: config.md.variant[variant]?.input?.height
                    },
                    '&_item': {
                        width: '100%',
                        height: '100%',
                        fontSize: toRem(16),
                        paddingLeft: toRem(23),
                        [theme.breakpoints.up('md')]: {
                            fontSize: toRem(18),
                            paddingLeft: toRem(18)
                        },
                        border: 'none !important',
                        '&:focus-visible': {
                            outline: 'none'
                        }
                    }
                },
                '&_submit': {
                    marginTop: toRem(30),
                    [theme.breakpoints.up('md')]: {
                        marginTop: config.md.variant[variant]?.button?.marginTop
                    },
                    '&_button': {
                        width: '100%',
                        height: toRem(50),
                        [theme.breakpoints.up('md')]: {
                            width: config.md.variant[variant]?.button?.width,
                            height: config.md.variant[variant]?.button?.height
                        },
                        '&_icon': {
                            width: toRem(20)
                        }
                    }
                },
                '&_success': {
                    width: '100%',
                    marginTop: toRem(11),
                    color: '#20AB3F',
                    fontSize: toRem(14),
                    fontWeight: 700,
                    lineHeight: 1.4,
                    fontStyle: 'italic'
                },
                '&_fail': {
                    width: '100%',
                    marginTop: toRem(11),
                    color: theme.colors.text.accent,
                    fontSize: toRem(14),
                    fontWeight: 700,
                    lineHeight: 1.4,
                    fontStyle: 'italic'
                }
            },
            '&_graphic_horizontal': {
                position: 'absolute',
                bottom: -10,
                left: 10,
                height: 10,
                width: '100%'
            },
            '&_graphic_vertical': {
                position: 'absolute',
                top: 7,
                width: 10,
                height: '100%',
                right: -10
            }
        }
    }
}

export default style
