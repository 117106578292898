import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { getWidgetListWithData } from '@hmn/rtl-web-core/helpers/category'
import safelyJoinSlugs from '@hmn/rtl-web-core/helpers/safelyJoinSlugs'
import { useListingInsertAds } from '@hmn/rtl-web-core/hooks/article/useAds'
import { IsolatedUseCategoryThirdParties } from '@hmn/rtl-web-core/hooks/category/useCategoryThirdParties'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import useCurrentPageNumber from '@hmn/rtl-web-core/hooks/useCurrentPageNumber'

import { listingInsertAds as insertAdsConfig, staticAds } from '@hmn/rtl-zena-ui/components/Ad/config/general'
import AdSlot from '@hmn/rtl-zena-ui/components/Ad/ZenaSlot.component'
import { articleCardDecorationVariants, articleCardVariants } from '@hmn/rtl-zena-ui/components/Article/components/Card'
import { Button, buttonVariants } from '@hmn/rtl-zena-ui/components/Button'
import { imageRatioVariants } from '@hmn/rtl-zena-ui/components/Image'

import { BaseLayout } from '../../layouts'
import { CategoryListingDefault, LayoutBuilder } from '..'
import { ComponentType, WidgetComponents } from '../LayoutBuilder/LayoutBuilder.component'
import OutOfPageAdSlots from '../OutOfPageAdSlots.component'
import Seo from '../ZenaSeo.component'

const { DELTA: A, ALPHA: B, GAMMA: C, BETA: D } = articleCardVariants

// Choosing Card Variant
const variantGrid = [
    [A, D, D],
    [C, C, C],
    [D, B],
    [C, C, C],
    [C, C, C]
].reduce((all, row) => [...all, ...row], [])

const itemsPerGridRow = [3, 3, 2, 3, 3]
const getRowInfo = index => {
    let count = index + 1
    const lastRowIndex = itemsPerGridRow.length - 1
    for (let rowInd = 0; rowInd <= lastRowIndex; rowInd += 1) {
        count -= itemsPerGridRow[rowInd]
        if (count <= 0) {
            return { index: rowInd, lastInRow: count === 0 }
        }
    }
    return {
        index: lastRowIndex + Math.ceil(count / itemsPerGridRow[lastRowIndex]),
        lastInRow: count % itemsPerGridRow[lastRowIndex] === 0
    }
}

const getArticleVariant = (index, perPage = 14) => (index < perPage ? variantGrid[index % variantGrid.length] : C)

// Choosing Card decoration Variant
const { ALPHA, BETA } = articleCardDecorationVariants

const decorationVariants = [
    [ALPHA, 0, 0],
    [0, 0, 0],
    [0, BETA],
    [0, 0, 0],
    [0, 0, 0]
].reduce((all, row) => [...all, ...row], [])

const getDecorationVariant = (index, perPage = 14) =>
    index < perPage ? decorationVariants[index % decorationVariants.length] || undefined : undefined

// Choosing grid width for specific item
const layoutGrid = [
    [7, 5, 5],
    [4, 4, 4],
    [6, 6],
    [4, 4, 4],
    [4, 4, 4]
].reduce((all, row) => [...all, ...row], [])

const getLayoutGridSize = (index, perPage = 14) => (index < perPage ? layoutGrid[index % layoutGrid.length] : 4)

// Aspect ratio of Card images in listing
const { CUSTOM_SQUARE, CUSTOM_PORTRAIT } = imageRatioVariants

// // Width of Card images in listing
const imageVariation = [
    [CUSTOM_SQUARE, CUSTOM_SQUARE, CUSTOM_SQUARE],
    [CUSTOM_PORTRAIT, CUSTOM_PORTRAIT, CUSTOM_PORTRAIT],
    [CUSTOM_SQUARE, CUSTOM_SQUARE],
    [CUSTOM_PORTRAIT, CUSTOM_PORTRAIT, CUSTOM_PORTRAIT],
    [CUSTOM_PORTRAIT, CUSTOM_PORTRAIT, CUSTOM_PORTRAIT]
].reduce((all, row) => [...all, ...row], [])

const getImageVariation = (index, perPage = 14) =>
    index < perPage ? imageVariation[index % imageVariation.length] : CUSTOM_PORTRAIT

const perPage = 50

export function Category({
    category,
    categoryLayout,
    categoryEntities,
    isLayout,
    isListing,
    gemiusId,
    slugs,
    navigation
}) {
    const currentPage = useCurrentPageNumber()

    const articles = categoryEntities
    const widgetListWithData = useMemo(
        () => (isLayout ? getWidgetListWithData(categoryLayout, WidgetComponents, ComponentType) : null),
        [isLayout, categoryLayout]
    )

    useEntityTypeDispatch('category')

    const insertAds = useListingInsertAds({
        insertAdsConfig,
        AdSlotComponent: AdSlot,
        ColumnComponent: Column
    })

    const handleGFCRevoke = () => {
        window.Didomi.preferences.show()
    }

    const parentCategoryLink = `/${safelyJoinSlugs(slugs?.slice(0, -1))}`

    const breadcrumbs = category?.path
        ?.map(path => path.title)
        .slice(0, -1)
        .join('/')

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedUseCategoryThirdParties
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_ZENA}
                homepageId={process.env.NEXT_PUBLIC_HOMEPAGE_ID_ZENA}
                gemiusId={gemiusId || process.env.NEXT_PUBLIC_GEMIUS_ID_ZENA}
                nodeId={category.id}
                node={category}
                slugs={slugs}
                isListing={isListing}
                isZena
            />
            <Seo item={category} listItems={articles} widgetListWithData={widgetListWithData} page={currentPage} />
            <>
                {isLayout ? (
                    <Row variant="category.main">
                        <Column variant="category.head" />
                        {category?.title === 'Pravila privatnosti' && (
                            <Column variant="category.privacyBtn">
                                <Button
                                    onClick={handleGFCRevoke}
                                    variant={buttonVariants.BETA}
                                    className="consent_btn"
                                    heightMd={50}
                                    heightXs={43}>
                                    Klikni za promjenu postavki Pravila kolačića i privatnosti
                                </Button>
                            </Column>
                        )}
                        <Media lessThan="md" scaleDown>
                            <LayoutBuilder
                                widgetListWithData={widgetListWithData?.mobile}
                                // @NOTE: upscore request
                                data-upscore-zone={
                                    category.id === process.env.NEXT_PUBLIC_HOMEPAGE_ID_ZENA ? 'homepage' : ''
                                }
                                deviceProp="mobile"
                            />
                        </Media>
                        <Media greaterThan="sm">
                            <LayoutBuilder
                                widgetListWithData={widgetListWithData?.desktop}
                                // @NOTE: upscore request
                                data-upscore-zone={
                                    category.id === process.env.NEXT_PUBLIC_HOMEPAGE_ID_ZENA ? 'homepage' : ''
                                }
                                deviceProp="desktop"
                            />
                        </Media>
                    </Row>
                ) : (
                    <CategoryListingDefault
                        articles={articles}
                        // @TODO: fix insert ads, they only take the first item for now
                        insertAds={insertAds}
                        getRowInfo={getRowInfo}
                        getArticleVariant={getArticleVariant}
                        getDecorationVariant={getDecorationVariant}
                        getLayoutGridSize={getLayoutGridSize}
                        getImageVariation={getImageVariation}
                        category={category}
                        perPage={perPage}
                        breadcrumbs={breadcrumbs}
                        parentCategoryLink={parentCategoryLink}
                    />
                )}

                <OutOfPageAdSlots config={staticAds} />
            </>
            )
        </BaseLayout>
    )
}

Category.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string.isRequired,
        sitemap: PropTypes.shape({
            href: PropTypes.string
        })
    }).isRequired,
    categoryLayout: PropTypes.shape({}),
    categoryEntities: PropTypes.arrayOf(PropTypes.shape({})),
    isLayout: PropTypes.bool.isRequired,
    isListing: PropTypes.bool.isRequired,
    slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
    gemiusId: PropTypes.string.isRequired,
    navigation: PropTypes.shape({}).isRequired
}

Category.defaultProps = {
    categoryLayout: null,
    categoryEntities: null
}
